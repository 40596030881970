@for (tab of tabList(); let index = $index; track tab.id) {
  <a
    #tabs_link
    [routerLink]="tab.link"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    (click)="scrollToLink(index)"
    class="tab-item"
    >{{ tab.name }}
    <div *ngIf="countsList().length > 0" class="count">
      {{ countsList()[index] }}
    </div>
  </a>
}
