import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { INITIAL_PER_PAGE } from '@settings/constants';
import { getPaginationData } from '@utils/getPaginationHeaders';
import { TFormattedApiError } from '@utils/formattingApiError';
import {
  TAnnouncementListData,
  TAnnouncementsListData,
} from '@components/pages/home/core/announcementsListTypes';
import { TOpenClientData } from '@components/pages/profile/profilePages/open-contacts/core/openContactsTypes';
import { TClientAnnouncementsStatusesCount } from './userContactsTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UserContactsService {
  #clientData = signal<TOpenClientData | null>(null);
  clientData = computed(this.#clientData);

  #clientId = signal<number | null>(null);
  clientId = computed(this.#clientId);

  #clientDataLoader = signal<boolean>(true);
  clientDataLoader = computed(this.#clientDataLoader);

  #clientDataError = signal<string | null>(null);
  clientDataError = computed(this.#clientDataError);

  #clientAnnouncementsStatusesCount = signal<number[]>([]);
  clientAnnouncementsStatusesCount = computed(
    this.#clientAnnouncementsStatusesCount,
  );

  #clientAnnouncementList = signal<TAnnouncementsListData>([]);
  clientAnnouncementList = computed(this.#clientAnnouncementList);

  #currentPage = signal<number>(0);
  currentPage = computed(this.#currentPage);

  #totalPage = signal<number>(0);
  totalPage = computed(this.#totalPage);

  #perPage = signal<number>(0);
  perPage = computed(this.#perPage);

  #totalCount = signal<number>(0);
  totalCount = computed(this.#totalCount);

  #loader = signal<boolean>(true);
  loader = computed(this.#loader);

  #infiniteLoader = signal<boolean>(false);
  infiniteLoader = computed(this.#infiniteLoader);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  constructor(private http: HttpClient) {}

  // Список объявлений определённого клиента
  loadClientAnnouncements({
    params,
    clientId,
    isInfinite,
  }: {
    params: Params;
    clientId: number;
    isInfinite?: boolean;
  }): void {
    if (this.#currentPage() >= this.#totalPage() && this.#currentPage() !== 0)
      return;

    if (isInfinite) {
      this.#infiniteLoader.set(true);
    } else {
      this.#loader.set(true);
    }

    this.#currentPage.set(this.#currentPage() + 1);

    const allParams: Params = {
      'per-page': INITIAL_PER_PAGE,
      page: this.#currentPage(),
      ...params,
    };

    this.http
      .get<TAnnouncementListData[]>(
        `${environment.getApiVersionUrl(`announcements/clients/${clientId}`)}`,
        {
          params: allParams,
          transferCache: {
            includeHeaders: [
              'X-Pagination-Current-Page',
              'X-Pagination-Page-Count',
              'X-Pagination-Per-Page',
              'X-Pagination-Total-Count',
            ],
          },
          observe: 'response',
        },
      )
      .subscribe({
        next: (response) => {
          const responsePagination =
            getPaginationData<TAnnouncementListData[]>(response);

          this.#currentPage.set(responsePagination.currentPage);
          this.#totalPage.set(responsePagination.totalPage);
          this.#perPage.set(responsePagination.perPage);
          this.#totalCount.set(responsePagination.totalCount);

          this.#clientAnnouncementList.set([
            ...this.#clientAnnouncementList(),
            ...responsePagination.data,
          ]);

          if (isInfinite) {
            this.#infiniteLoader.set(false);
          } else {
            this.#loader.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);

          if (isInfinite) {
            this.#infiniteLoader.set(false);
          } else {
            this.#loader.set(false);
          }
        },
      });
  }

  // Количество активных и неактивных объявлений определенного клиента
  loadClientAnnouncementsStatusesCount(clientId: number): void {
    this.http
      .get<TClientAnnouncementsStatusesCount>(
        `${environment.getApiVersionUrl(`announcements/clients/${clientId}/counts`)}`,
      )
      .subscribe({
        next: (response) => {
          this.#error.set(null);
          this.#clientAnnouncementsStatusesCount.set(Object.values(response));
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);
        },
      });
  }

  // Просмотр определенного клиента
  loadClientData(id: number): Observable<TOpenClientData> {
    return this.http.get<TOpenClientData>(
      `${environment.getApiVersionUrl(`clients/${id}`)}`,
    );
  }

  changeAnnouncementIsFavorite(slug: string) {
    this.#clientAnnouncementList().map((item) =>
      item.slug === slug ? (item.is_favorite = !item.is_favorite) : null,
    );
  }

  changeUserIsFavorite() {
    const data = this.#clientData();

    if (data) this.#clientData.set({ ...data, is_favorite: !data.is_favorite });
  }

  setClientData(newValue: TOpenClientData | null) {
    this.#clientData.set(newValue);
  }

  setClientError(newValue: string | null) {
    this.#clientDataError.set(newValue);
  }

  setClientId(newValue: number) {
    this.#clientId.set(newValue);
  }

  resetUserContactsData() {
    this.#clientAnnouncementList.set([]);
    this.#currentPage.set(0);
    this.#totalPage.set(0);
    this.#perPage.set(0);
    this.#totalCount.set(0);
    this.#loader.set(true);
    this.#infiniteLoader.set(false);
    this.#error.set(null);
  }
}
